
import bus from "@/utils/bus";
import MyTable from "@/components/MyTable/index.vue";
import MyDialog from "@/components/Dialog/index.vue";
import MySelect from "@/components/MySelect/index.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
  onUnmounted,
} from "vue";
export default defineComponent({
  name: "",
  components: { MyTable, MyDialog, MySelect },
  setup() {
    const Dialog = ref();
    const data = reactive({
      list: [],
      Title: [
        { label: "用户名", value: "user" },
        { label: "账号", value: "Account" },
        { label: "角色", value: "role" },
        { label: "状态", value: "type" },
      ],
      Arr: [
        {
          user: "张三",
          Account: "15152089358",
          role: "现场管理人员",
          type: "启用",
        },
        {
          user: "张三",
          Account: "15152089358",
          role: "现场管理人员",
          type: "启用",
        },
      ],
      operateList: [
        { id: 1, name: "禁用", callout: "禁用" },
        { id: 2, name: "编辑", callout: "compile" },
        { id: 3, name: "重置密码", callout: "reset" },
      ],
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
        resource: "启用",
      },
      showName: "",
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      bus.on("getDialog", getDialog);
    });
    onUnmounted(() => {
      bus.off("getDialog");
    });
    const getDialog = () => {
      data.showName = "compile";

      Dialog.value.getShow({ name: "新增用户" });
    };

    const AllType = (item: Object, type: any) => {
      data.showName = type;
      let name = data.operateList.filter((item: any) => item.callout == type)[0]
        .name;
      Dialog.value.getShow({
        name: `${name}`,
      });
    };

    const getChange = () => {};

    const getConfirm = () => {};
    return {
      ...toRefs(data),
      Dialog,
      getConfirm,
      getChange,
      AllType,
    };
  },
});
